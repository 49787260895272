<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0" v-if="showForm === false">
      <b-col lg="12" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <div class="row w-full">
          <div class="col-md-3 mx-auto">
            <div
                class="text-center  my-2"
            >
              <b-spinner class="align-middle"></b-spinner>
              <br>
              <strong>{{ $t('Message.loading') }}...</strong>
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Reset password-->
    </b-row>
    <b-row class="auth-inner m-0" v-else>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img :src="imgUrl" fluid alt="Register V2"/>
        </div>
      </b-col>
      <!-- /Left Text-->


      <!-- Reset password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t(locale.title) }} 🔒
          </b-card-title>
          <b-card-text class="mb-2" v-text="$t(locale.subtitle)"/>

          <!-- form -->
          <validation-observer ref="form">
            <b-form
                class="auth-reset-password-form mt-2"
                method="POST"
                @submit.prevent="validationForm"
            >
              <!-- password -->
              <b-form-group
                  :label="$t(locale.passwordLabel)"
                  label-for="reset-password-new"
              >
                <validation-provider
                    #default="{errors}"
                    :name="$t(locale.passwordLabel)"
                    vid="password"
                    rules="required|password"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="{
                                            'is-invalid': errors.length > 0,
                                        }"
                  >
                    <b-form-input
                        id="reset-password-new"
                        v-model="password"
                        :type="password1FieldType"
                        :state="
                                                errors.length > 0 ? false : null
                                            "
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="
                                                    togglePassword1Visibility
                                                "
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                      class="text-danger"
                      v-text="errors[0]"
                  />
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                  label-for="reset-password-confirm"
                  :label="$t(locale.passwordConfirm)"
              >
                <validation-provider
                    #default="{errors}"
                    :name="$t(locale.passwordConfirm)"
                    rules="required|confirmed:password"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                  >
                    <b-form-input
                        id="reset-password-confirm"
                        v-model="cPassword"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="
                                                errors.length > 0 ? false : null
                                            "
                        name="reset-password-confirm"
                        placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="
                                                    togglePassword2Visibility
                                                "
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                      class="text-danger"
                      v-text="errors[0]"
                  />
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                  block
                  type="submit"
                  variant="primary"
                  v-text="$t(locale.resetPassword)"
              />
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name: 'login'}">
              <feather-icon icon="ChevronLeftIcon"/>
              {{ $t(locale.backToLogin) }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate';

import {required} from '@validations';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: "completeProfile",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      showForm: true,
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      locale: {
        title: 'companyProfilePage.CardTitle',
        subtitle: 'companyProfilePage.CardSubtitle',
        passwordLabel: 'companyProfilePage.PasswordLabel',
        passwordConfirm: 'companyProfilePage.PasswordConfimationLabel',
        resetPassword: 'companyProfilePage.Button',
        backToLogin: 'companyProfilePage.BackToLogin',
        msgFailed: 'Message.Failed',
      },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
          ? 'EyeIcon'
          : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password'
          ? 'EyeIcon'
          : 'EyeOffIcon';
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password'
          ? 'EyeIcon'
          : 'EyeOffIcon';
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
          this.password1FieldType === 'password' ? 'text' : 'password';
    },
    togglePassword2Visibility() {
      this.password2FieldType =
          this.password2FieldType === 'password' ? 'text' : 'password';
    },
    validationForm() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.$useJwt
              .resetUpdatePassword({
                token: this.$route.query.token,
                password: this.password,
              })
              .then(res => {
                let msg =
                    localStorage.getItem('lang') == 'sv'
                        ? res.data.message
                        : res.data.eng_message;

                this.$router.push({name: 'login'}).then(() => {
                  this.popup(
                      msg,
                      'success',
                      this.$t('Message.Success')
                  );
                });
              })
              .catch(err => {
                if (err.response) {
                  let msg =
                      localStorage.getItem('lang') === 'sv'
                          ? err.response.data.message
                          : err.response.data.eng_message;
                  this.popup(
                      msg,
                      'danger',
                      this.$t('Message.Failed')
                  );
                  this.$refs.form.setErrors({
                    password: [msg],
                  });
                }
              });
        }
      });
    },
    popup(message, variant, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          text: message,
          title: title,
          icon:
              variant == 'success'
                  ? 'CheckIcon'
                  : 'AlertTriangleIcon',
          variant: variant,
        },
      });
    },
    processCompletedProfile() {
      const {token} = this.$route.query
      if (token) {
        this.showForm = true
      } else {
        this.showForm = false
        this.popup(this.$t('verificationTokenMissing'), 'error', 'Error')
        let self = this
        setTimeout(function () {
          self.$router.push({name: 'login'})
        }, 200)
      }
    }
  },
  mounted() {
    let self = this
    setTimeout(function () {
      self.processCompletedProfile()
    }, 1000)
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
